import { template as template_9235a55a1f0f48b28d12feea49d8b2fa } from "@ember/template-compiler";
const FKLabel = template_9235a55a1f0f48b28d12feea49d8b2fa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
