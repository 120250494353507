import { template as template_e13a37079c694f3b9e3920c4e71d3f34 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e13a37079c694f3b9e3920c4e71d3f34(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
