import { template as template_d4c0226f69134675a30d3693047dce98 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { hash } from "@ember/helper";
import { action } from "@ember/object";
import FKControlConditionalDisplayCondition from "./conditional-content/condition";
import FKControlConditionalContentContent from "./conditional-content/content";
const Conditions = template_d4c0226f69134675a30d3693047dce98(`
  <div class="form-kit__inline-radio">
    {{yield
      (component
        FKControlConditionalDisplayCondition
        activeName=@activeName
        setCondition=@setCondition
      )
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const Contents = template_d4c0226f69134675a30d3693047dce98(`
  {{yield
    (component FKControlConditionalContentContent activeName=@activeName)
  }}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default class FKControlConditionalContent extends Component {
    @tracked
    activeName = this.args.activeName;
    @action
    setCondition(name) {
        this.activeName = name;
    }
    static{
        template_d4c0226f69134675a30d3693047dce98(`
    <div class="form-kit__conditional-display">
      {{yield
        (hash
          Conditions=(component
            Conditions activeName=this.activeName setCondition=this.setCondition
          )
          Contents=(component Contents activeName=this.activeName)
        )
      }}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
